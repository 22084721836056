import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { AdmissionYearOptions, Breadcrumb, EBtnProperties } from '@enum';
import { btnProperties } from '@models';
import { GlobalService } from '@services';
import { HeaderService } from '@services';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { chatBackground } from '@assets';
import { ChatService } from '@services';
import { chatProfileIcon, broadcastProfileIcon } from '@assets';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FileUploadService } from '@services';
import { MessageService } from 'primeng/api';
import { Severity, Summary, PassoutYearsOptions, Students, Message } from '@enum';
import { TableComponent } from 'src/app/shared/components/table/table.component';
import { Router } from '@angular/router';
import { StudentService } from '@services';
interface IFilterData {
  filterName: string;
  key: string;
  array: any[];
}

@Component({
  selector: 'app-reminder-queue',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
})
export class ChatComponent implements OnInit, OnDestroy {
  @ViewChild(TableComponent) tableComponent!: TableComponent;
  @ViewChild('actionTemplate') actionTemplateRef!: TemplateRef<any>;
  @ViewChild('userChatContainer') userChatContainer!: ElementRef;
  @ViewChild('messageInput') messageInput!: ElementRef;
  @ViewChild('imageInput') imageInput!: ElementRef<HTMLInputElement>;
  @ViewChild('videoInput') videoInput!: ElementRef<HTMLInputElement>;
  @ViewChild('documentInput') documentInput!: ElementRef<HTMLInputElement>;
  @ViewChild('templateButton', { static: false }) templateButton!: ElementRef;
  @ViewChild('broadcastScrollContainer') broadcastScrollContainer!: ElementRef;
  @ViewChild('chatScrollContainer') chatScrollContainer!: ElementRef;
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;

  userData: any = localStorage.getItem('user_details');
  accountData: any = localStorage.getItem('account_details');
  accountMetaData: any = localStorage.getItem('account_metadata');
  btnProperties: { [key: string]: btnProperties } = EBtnProperties;
  isLoading: boolean = false;
  isLoadingDetails: boolean = false;
  searchValue: any = '';
  studentSearchValue: any = '';
  group_account_id: string;
  driveLabel: string = '';
  chatBackground = chatBackground;
  currentChatScroll: number = 0;
  currentAllScroll: number = 0;
  currentBroadcastScroll: number = 0;
  limit: number = 10;
  allChatList: any = [];
  chatList: any = [];
  broadcastList: any = [];
  templateData: any = {};
  chatProfileIcon = chatProfileIcon;
  broadcastProfileIcon = broadcastProfileIcon;
  passoutYearsOptions = PassoutYearsOptions;
  selectedChat: any = {};
  selectedChatConversations: any = {};
  isChatLoading: boolean = false;
  isBold = false;
  messageText: string = '';
  showFileOptions = false;
  showFileInput = false;
  fileInputAccept = '';
  isMultiline: boolean = false;
  showMultilineOverlay: boolean = false;
  replyContext: string | null = null;
  showMessageOptions: any = '';
  selectedFileType: any = '';
  selectedFile: File | null = null;
  filePreviews: { file: File; fileType: string; fileName: string }[] = [];
  isFileUploading: boolean = false;
  sendingMessage: boolean = false;
  showTemplateDialog: boolean = false;
  dialogStyle: { [key: string]: string } = {};
  selectedTemplate: any = null;
  placeholders: string[] = [];
  templateDataOptions: any = [];
  fetchInterval: any;
  bodyPlaceholders: any = [];
  buttonPlaceholders: any = [];
  headerPlaceholders: any = [];
  loading: boolean = false;
  hasMoreData: boolean = true;
  chatType: string = 'all';
  openNewBroadcast: boolean = false;
  broadcastName: string = '';
  broadcastBy: any;
  selectedBroadcastType: any;
  broadcastCreateOptions: any = [];
  searchPlaceholders = 'Broadcast Name, Email, Registration No';
  isChatScrolling: boolean = false;
  isAllScrolling: boolean = true;
  isChangeDisabled: boolean = false;
  isBroadcastScrolling: boolean = false;
  broadcastInfo: any = [];
  showSidebar: boolean = false;
  selectedChatDetail: any = {};
  selectedPassoutYear: string = '';
  broadcastOptions: any = [];
  isWhatsappEnabled: boolean = false;
  campusDetails: any[] = [];
  selectedCampuses: any = null;
  departmentDatas: any[] = [];
  campusSelected: string = ''
  selectedAcademics: string = ''
  academicsOptions: any[] = []
  departmentOptions: any[] = [];
  selectedDepartment: any = null;
  selectedDegreeSpec: any = null;
  selectedProgramme: any[] = [];
  degreeSpecOptions: any[] = [];
  enteredAdmissionYear: string = '';
  enteredPassedOutYear: string = '';
  totalPage: number = 0;
  rowsize: number = 10;
  selectedPage: any = 1;
  sort_by = '';
  sort_order = 2;
  selectAllStudentCount: number = 0;
  selectAllStudents: boolean = false;
  tableHeight: string = '60vh'
  modalStyle: { [key: string]: string } = {};
  search: string = 'Email/Registration Number/Mobile Number';
  isUsersModalVisible = false;
  showDropdown: boolean = false;
  showConfirmDialog = false;
  dialogMode: 'edit' | 'delete' = 'edit';
  tableType: string = 'selected';
  dialogHeader: string = '';
  confirmationInput: string = '';
  searchTerm = '';
  filteredUsers: any = [];
  isBroadcastStep2: boolean = false;
  captionsList: any = { actions: false, columns: true, download: false, customPassword: false, setTableDefault: false, upload: false, filter: false, search: true, pagination: true, header: true, checkbox: true, limit: true, custom_sort: false, sendEmail: false, changeRequest: false };
  items = [
    {
      icon: 'pi pi-users',
      badge: this.broadcastInfo.length
    },
    {
      icon: 'pi pi-ellipsis-v',
      items: [
        {
          label: 'Edit Broadcast Name',
          icon: 'pi pi-pencil',
        },
        {
          label: 'Delete Broadcast',
          icon: 'pi pi-trash',
        }
      ]
    },
  ]
  actionOptions: any[] = [];
  pageLimits: any[] = [{ label: 10, value: 10 }, { label: 25, value: 25 }, { label: 50, value: 50 }, { label: 100, value: 100 }];
  admissionYearOptions: any[] = AdmissionYearOptions;
  passOutYearOptions: any[] = PassoutYearsOptions;
  studentsEnum: any = Students;
  filteredDepartments: any[] = [];
  dataList: any[] = [];
  dataCount: number = 0;
  existingStudents: any[] = [];
  existingStudentsCount: number = 0;
  selectedStudentCount: number = 0;
  selectedStudents: string[] = [];
  newlyAddedStudents: string[] = [];
  afterRemovedStudentCount: number = 0;
  filterData: IFilterData[] = [
    {
      filterName: "Campus",
      key: 'campus',
      array: []
    },
    {
      filterName: "Pass Out Year",
      key: 'pass_out_year',
      array: PassoutYearsOptions
    },
    {
      filterName: "Department",
      key: 'department',
      array: []
    },
    {
      filterName: "Degree",
      key: 'degree',
      array: []
    }
  ];
  selectedFilters: any = {
    campus: [],
    pass_out_year: [],
    department: [],
    degree: []
  };
  fileTypes: string[] = ['image', 'video', 'document', 'audio'];
  docMaxSize: any = {
    'document': '100 MB',
    'image': '5 MB',
    'video': '16 MB'
  }
  chatTypeOptions = [
    { label: 'All', value: 'all', disabled: true },
    { label: 'Chats', value: 'chats', disabled: true },
    { label: 'Broadcast', value: 'broadcast', disabled: true }
  ];
  columns = [
    { field: 'primary_email', header: 'Email', freezable: true, sortable: true, tableDefault: true, width: '20vw' },
    { field: 'mobile_number', header: 'Mobile Number', freezable: false, sortable: true, tableDefault: true, width: '20vw' },
    { field: 'first_name', header: 'Name', freezable: false, sortable: true, tableDefault: true, width: '14vw', combineCols: 'last_name', type: 'combine' },
    { field: 'registration_number', header: 'Registration Number', freezable: false, sortable: true, tableDefault: false, width: '15vw' },
    { field: 'campus', header: 'Campus', freezable: false, sortable: true, tableDefault: true, width: '15vw' },
    { field: 'admission_year', header: 'Admission Year', freezable: false, sortable: true, tableDefault: true, width: '13vw' },
    { field: 'passed_out_year', header: 'Pass Out Year', freezable: false, sortable: true, tableDefault: true, width: '13vw' },
    { field: 'department', header: 'Department', freezable: false, sortable: true, tableDefault: true, width: '20vw' },
    { field: 'degree_specialization', header: 'Degree & Specialization', freezable: false, sortable: true, tableDefault: true, width: '20vw' },
  ];
  tabItems: any[] = [];
  activeItem: any | undefined;

  constructor(
    private fileUploadService: FileUploadService,
    public globalService: GlobalService,
    private headerService: HeaderService,
    private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService,
    private chatService: ChatService,
    private sanitizer: DomSanitizer,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
    private studentService: StudentService,
    private router: Router,
  ) {
    this.accountData = JSON.parse(this.accountData);
    this.userData = JSON.parse(this.userData);
    this.group_account_id = this.accountData.group_account_id;
    this.formatData();
  }

  async ngOnInit() {
    this.isWhatsappEnabled = JSON.parse(this.accountMetaData)?.general?.wp_integration;

    if(!this.isWhatsappEnabled)
    {
      return;
    }

    const [templates, campusRes] = await Promise.all([this.chatService.fetchTemplates(), this.fetchCampusDetails()]);
    this.templateData = templates?.data;
    this.chatTypeChange({ value: this.chatType });
    this.updateBreadCrumb();
  }

  async onActiveTableChange(event: any) {
    this.activeItem = event;
    this.tableType = event.value;
    this.studentSearchValue = '';
    this.actionOptions = [];
    if (this.tableType === 'existing') {
      this.actionOptions.push(
        {
          item_name: 'Remove Students', type: 'removeFromBroadcast', item_type: 'button'
        });
      this.isLoading = true;
      let res: any = await this.chatService.fetchBroadcastUsers(this.selectedChat.broadcast_id);
      this.existingStudents = res.data;
      this.selectedStudents = this.existingStudents.map(user => user._id);
      this.dataList = this.existingStudents.slice(0,10);
      this.dataCount = this.existingStudents.length;
      this.totalPage = Math.ceil(this.dataCount / this.rowsize);
      this.isLoading = false;
    }
    else {
      this.actionOptions.push(
        {
          item_name: 'Add Students', type: 'addToBroadcast', item_type: 'button'
        });
      this.resetFilter();
      this.fetchStudents();
    }
  }

  applyFrontendFilters() {
    let filteredData = this.existingStudents;
  
    if (this.selectedFilters['campus']?.length) {
      const campusSet = new Set(this.selectedFilters['campus']);
      filteredData = filteredData.filter((student) => campusSet.has(student.account_id));
    }
  
    if (this.selectedFilters['department']?.length) {
      const departmentSet = new Set(this.selectedFilters['department']);
      filteredData = filteredData.filter((student) => departmentSet.has(student.department_id));
    }
  
    if (this.selectedFilters['degree']?.length) {
      const degreeFilter = this.selectedFilters['degree'];
      filteredData = filteredData.filter((student) =>
        degreeFilter.some(
          (filter: any) =>
            filter.degree_id === student.degree_id &&
            filter.programme_id === student.programme_id
        )
      );
    }
  
    if (this.selectedFilters['pass_out_year']?.length) {
      const yearSet = new Set(this.selectedFilters['pass_out_year']);
      filteredData = filteredData.filter((student) => yearSet.has(student.passed_out_year));
    }
  
    this.dataList = filteredData;
    this.dataCount = this.dataList.length;
    this.totalPage = Math.ceil(this.dataCount / this.rowsize);
  }

  async fetchStudents()
  {
    this.isLoading = true;
    const payload = {
      limit: this.rowsize,
      page: this.selectedPage,
      search: this.studentSearchValue,
      filters: this.getSelectedFilters(),
      fields: this.columns.map(col => col?.field),
      sort_by: this.sort_by,
      sort_order: this.sort_order,
      group_account_id: this.group_account_id,
      must_not: this.broadcastInfo.map((info: any) => info.user_id)
    };
    const response: any = await this.studentService.fetchStudentDetails(payload);
    if (response.success) {
      this.dataList = response.data.data;
      this.dataCount = response.data.total_count;
      this.totalPage = Math.ceil(this.dataCount / this.rowsize);
    }
    else {
      this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: Message.FETCHSTUDENTERR,
      });
    }
    this.isLoading = false;
  }

  formatData()
  {
    this.globalService.driveLabelSubject.subscribe((label) => {
      const {
        drive_label,
      } = label.labeling;
      const { label_singular, optin_past, optout_past } =
        this.globalService.getLabels(label.labeling);
      this.driveLabel = drive_label;
      this.broadcastOptions = [{ label: this.driveLabel, value: 'drives' },
        { label: 'Degree Specialisation', value: 'degree_spec' },
        { label: 'Passout Year', value: 'passout_year' }];
    
      });
      this.headerService.updateHeaderState('/chat', []);
  }

  async fetchChats() {
    if (this.isChatScrolling) return;
  
    try {
      this.isChatScrolling = true;
  
      const res: any = await this.chatService.fetchChats({
        offset: ++this.currentChatScroll,
        limit: this.limit,
        type: 'chats',
        ...(this.searchValue.length ? { search: this.searchValue } : {}),
      });
  
      if (!res?.success) {
        console.error('Failed to fetch chats:', res?.message || 'Unknown error');
        return;
      }
  
      const newChats = res.data;
  
      this.hasMoreData = newChats?.hasMoreData ?? false;
      this.chatList = [
        ...this.chatList,
        ...this.processFetchedChats(newChats?.data || []),
      ];
      this.chatList = this.sortArrayByTimestamp(
        this.chatList,
        'lastTimestamp',
        'desc'
      );
    } catch (error) {
      console.error('Error in fetchChats:', error);
    } finally {
      this.isChatScrolling = false;
    }
  }
  
  async fetchBroadcasts(fromStart?: boolean) {
    if (this.isBroadcastScrolling) return;
  
    try {
      this.isBroadcastScrolling = true;
  
      const res: any = await this.chatService.fetchChats({
        search: this.searchValue,
        ...(fromStart ? { offset: 0 } : { offset: ++this.currentBroadcastScroll }),
        limit: this.limit,
        type: 'broadcasts',
      });
  
      if (!res?.success) {
        console.error('Failed to fetch broadcasts:', res?.message || 'Unknown error');
        return;
      }
  
      if (res?.data?.length < this.limit) {
        this.hasMoreData = false;
      }
  
      const fetchedData = this.processFetchedChats(res?.data || []);
      this.broadcastList = fromStart
        ? fetchedData
        : [...this.broadcastList, ...fetchedData];
  
      this.broadcastList = this.sortArrayByTimestamp(
        this.broadcastList,
        'lastTimestamp',
        'desc'
      );
    } catch (error) {
      console.error('Error in fetchBroadcasts:', error);
    } finally {
      this.isBroadcastScrolling = false;
    }
  }

  async fetchAllCommunication(fromStart?: boolean) {
    if (this.isAllScrolling) return;

    try {
      this.isAllScrolling = true;

      const res: any = await this.chatService.fetchChats({
        search: this.searchValue,
        ...(fromStart ? { offset: 0 } : { offset: ++this.currentAllScroll }),
        ...(fromStart ? { limit: this.limit } : { limit: this.limit }),
        type: 'all',
      });

      if (!res?.success) {
        console.error('Failed to fetch all communications:', res?.message || 'Unknown error');
        return;
      }

      this.hasMoreData = res?.data?.hasMoreBroadcasts || res?.data?.hasMoreChats;

      const fetchedData = this.processFetchedChats([
        ...(res?.data?.broadcasts || []),
        ...(res?.data?.chats || []),
      ]);

      this.allChatList = fromStart
        ? fetchedData
        : [...this.allChatList, ...fetchedData];

      this.allChatList = this.sortArrayByTimestamp(
        this.allChatList,
        'lastTimestamp',
        'desc'
      );
    } catch (error) {
      console.error('Error in fetchAllCommunication:', error);
    } finally {
      this.isAllScrolling = false;
    }
  }

  reloadSelectedChat() {
    this.searchValueChange({
      target: {
        value: ''
      }
    })
  }

  async fetchCampusDetails() {
    const response = await this.studentService.fetchCampusDetails(this.group_account_id);
    if (response.success) {
      this.campusDetails = response.data;
      this.updateFilterData();
    }
    else {
      this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: Message.ADMISSIONERROR,
      });
    }
  }

  private updateFilterData() {
    this.filterData[0].array = [];
    this.filterData[2].array = [];
    this.filterData[3].array = [];

    const uniqueItems = {
      campus: new Set<string>(),
      department: new Set<string>(),
      degree: new Set<string>()
    };

    this.campusDetails.forEach((item) => {
      this.addUniqueItem(uniqueItems.campus, item.account_id, 0, item.full_name);
      this.addUniqueItem(uniqueItems.department, item.department_id, 2, item.department);
      this.addUniqueDegree(item, uniqueItems.degree);
    });

    this.sortFilter([0, 2, 3]);
  };

  private addUniqueDegree(item: any, degreeSet: Set<string> ) {
    const key = `${item.degree_id}-${item.programme_id}`;
    if (!degreeSet.has(key)) {
      degreeSet.add(key);
      this.filterData[3].array.push({
        label: `${item.degree_name} - ${item.specialization}`,
        value: { degree_id: item.degree_id, programme_id: item.programme_id }
      });
    }
  };

  addUniqueItem(set: Set<string>, id: string, index: number, label: string) {
    if (!set.has(id)) {
      set.add(id);
      this.filterData[index].array.push({ label, value: id });
    }
  };

  async handleFilterChange(selectedFilter: string, event: any) {
    if(this.tableType === 'selected')
    {
      if (selectedFilter === 'campus') {
        this.filterData[2].array = [];
        this.filterData[3].array = [];
        this.selectedFilters['degree'] = [];
        this.selectedFilters['department'] = [];
        this.handleCampusFilter(event.value);
      } else if (selectedFilter === 'department') {
        this.filterData[3].array = [];
        this.selectedFilters['degree'] = [];
        this.handleDepartmentFilter(event.value);
      }
      await this.fetchStudents()
    }
    else {
      this.applyFrontendFilters();
    }
  };

  private handleCampusFilter(selectedCampuses: string[]) {
    const eventValueSet = new Set(selectedCampuses);

    this.filteredDepartments = this.campusDetails.filter(element =>
      eventValueSet.has(element.account_id)
    );
    const uniqueItems = {
      department: new Set<string>(),
      degree: new Set<string>()
    };

    const selected = this.filteredDepartments.length ? this.filteredDepartments : this.campusDetails;

    selected.forEach((element: any) => {
      this.addUniqueItem(uniqueItems.department, element.department_id, 2, element.department);
      this.addUniqueDegree(element, uniqueItems.degree);
    });

    this.sortFilter([2, 3]);
  };

  private handleDepartmentFilter(selectedDepartments: any[]) {
    const eventValueSet = new Set(selectedDepartments);
    
    const degreeSet = new Set<string>();
    const selected = this.filteredDepartments.length ? this.filteredDepartments : this.campusDetails;

    selected.forEach(element => {
      if (eventValueSet.size == 0 || eventValueSet.has(element.department_id)) {
        this.addUniqueDegree(element, degreeSet);
      }
    });

    this.sortFilter([3]);
  };

  private sortFilter(sortItems: number[]) {
    sortItems.forEach((each) => {
      this.filterData[each].array.sort((a: any, b: any) => a.label.localeCompare(b.label));
    })
  };

  async searchChange(searchValue: any) {
    this.searchValue = searchValue;
    this.selectedPage = 1;
  
    if (this.tableType === 'existing') {
      this.filterExistingStudents();
    } else {
      await this.fetchStudents();
    }
  }
  
  async tableSearchValueChange(searchValue: any) {
    this.studentSearchValue = searchValue;
    this.selectedPage = 1;
  
    if (this.tableType === 'existing') {
      this.filterExistingStudents();
    } else {
      await this.fetchStudents();
    }
  }
  
  async pageChange(selectedPage: any) {
    this.selectedPage = selectedPage;
  
    if (this.tableType === 'existing') {
      this.paginateExistingStudents();
    } else {
      await this.fetchStudents();
    }
  }
  
  async rowLengthChange(length: any) {
    this.rowsize = length;
    this.selectedPage = 1;
  
    if (this.tableType === 'existing') {
      this.paginateExistingStudents();
    } else {
      await this.fetchStudents();
    }
  }
  
  filterExistingStudents() {
    let filteredStudents = [...this.existingStudents];
  
    if (this.studentSearchValue) {
      const searchValue = this.studentSearchValue.toLowerCase();
      filteredStudents = filteredStudents.filter(student =>
        student.primary_email.toLowerCase().includes(searchValue) || 
        student.mobile_number.toString().includes(searchValue) ||
        student.registration_number.toLowerCase().includes(searchValue)
      );
    }    
  
    Object.keys(this.selectedFilters).forEach(filterKey => {
      const selectedValues = this.selectedFilters[filterKey];
      if (selectedValues && selectedValues.length > 0) {
        filteredStudents = filteredStudents.filter(student =>
          selectedValues.includes(student[filterKey])
        );
      }
    });
  
    this.dataList = filteredStudents.slice((this.selectedPage - 1) * this.rowsize, this.selectedPage * this.rowsize);
    this.dataCount = filteredStudents.length;
    this.totalPage = Math.ceil(this.dataCount / this.rowsize);
  }
  
  paginateExistingStudents() {
    const startIndex = (this.selectedPage - 1) * this.rowsize;
    const endIndex = startIndex + this.rowsize;
    this.dataList = this.existingStudents.slice(startIndex, endIndex);
    this.dataCount = this.existingStudents.length;
    this.totalPage = Math.ceil(this.dataCount / this.rowsize);
  }

  async fetchDepartmentDegreeSpec() {
    this.departmentOptions = []
    this.selectedDepartment = null;
    this.degreeSpecOptions = []
    this.selectedDegreeSpec = null;
    this.isLoading = true;
    const response: any = await this.studentService.fetchDepartmentDegreeSpecialization(this.selectedCampuses.value);
    if (response.success) {
      const departments = new Map();
      this.departmentDatas = response.data
      this.departmentDatas.forEach((result: any) => {
        const { department_id, department } = result;

        if (!departments.has(department)) {
          departments.set(department, true);
          const departmentValue = {
            department_id: department_id,
            department: department
          }
          this.departmentOptions.push({ label: department, value: departmentValue });
        }
      });
    }

    this.enteredAdmissionYear = '';
    this.enteredPassedOutYear = '';
    this.isLoading = false;
  }

  async fetchPassOutYear() {
    this.passOutYearOptions = [];
    this.enteredPassedOutYear = '';
    this.passOutYearOptions = PassoutYearsOptions.filter((year: any) => year.value > this.enteredAdmissionYear);
  }

  async departmentChange() {
    this.degreeSpecOptions = []
    const degreeSpec = new Map();
    this.departmentDatas.forEach((result: any) => {
      const { specialization, programme_id, degree_name, degree_id, specialization_minor, department_id } = result;
      if (!degreeSpec.has(programme_id) && this.selectedDepartment.value.department_id === department_id) {
        degreeSpec.set(programme_id, true);
        const values = { programme_id, degree_id, specialization, specialization_minor, degree_name, degree_specialization: `${degree_name} - ${specialization}` };
        const labels = `${degree_name} - ${specialization}`
        this.degreeSpecOptions.push({ label: labels, value: values });
      }
    });
  }

  private sortArrayByTimestamp(
    array: any[],
    timestampFieldPath: string,
    order: 'asc' | 'desc' = 'desc'
  ): any[] {
    return array?.sort((a: any, b: any) => {
      const timestampA = this.getTimestamp(a, timestampFieldPath);
      const timestampB = this.getTimestamp(b, timestampFieldPath);

      return order === 'asc'
        ? timestampA - timestampB
        : timestampB - timestampA;
    });
  }

  async tableAction(event: { type: string; student_data: any[]; selectAll: boolean; }) {
    const { type, student_data, selectAll } = event;
    try {
      if (type === 'addToBroadcast') {
        if (!selectAll) {
          const incomingUserIds = student_data?.map(student => student?.user_id);
          this.newlyAddedStudents = Array.from(new Set([...incomingUserIds]));
          this.selectedStudentCount = this.newlyAddedStudents.length;
          this.messageService.add({
            severity: 'success',
            summary: 'Students Selected',
            detail: `${this.selectedStudentCount} students selected for broadcast.`,
          });
        } else {
          this.isLoading = true;
          const payload = {
            limit: this.dataCount,
            page: 1,
            search: this.studentSearchValue,
            filters: this.getSelectedFilters(),
            fields: ['user_id'],
            sort_by: this.sort_by,
            sort_order: this.sort_order,
            group_account_id: this.group_account_id,
            must_not: this.broadcastInfo.map((info: any) => info.user_id)
          };
          const response: any = await this.studentService.fetchStudentDetails(payload);
          if (response.success) {
            const incomingUserIds = response.data.data?.map((student: any) => student?.user_id);
            const spreadArray = [...incomingUserIds];
            if (this.isEmptyObject(this.selectedChat)) {
              spreadArray.push(...this.selectedStudents);
            }
            this.newlyAddedStudents = Array.from(new Set(spreadArray));
            this.selectedStudentCount = this.newlyAddedStudents.length;
            this.messageService.add({
              severity: 'success',
              summary: 'Students Selected',
              detail: `${this.selectedStudentCount} students selected for broadcast.`,
            });
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Failed to fetch student details.',
            });
          }
          this.isLoading = false;
        }
  
        if (!this.isEmptyObject(this.selectedChat)) {
          await this.createBroadcast();
        }
      } else if (type === 'removeFromBroadcast') {
        if (!selectAll) {
          const userIdsToRemove = student_data?.map(student => student?.user_id);
          if (this.tableType === 'selected') {
            this.newlyAddedStudents = this.newlyAddedStudents.filter(userId => !userIdsToRemove.includes(userId));
            this.selectedStudentCount = this.newlyAddedStudents.length;
            this.messageService.add({
              severity: 'info',
              summary: 'Students Selected',
              detail: `${userIdsToRemove.length} students cleared from broadcast selection.`,
            });
          } else {
            this.selectedStudents = this.selectedStudents.filter(userId => !userIdsToRemove.includes(userId));
            this.afterRemovedStudentCount = this.selectedStudents.length;
            this.messageService.add({
              severity: 'info',
              summary: 'Students Selcted',
              detail: `${userIdsToRemove.length} students cleared from broadcast selection.`,
            });
          }
        } else {
          if (this.tableType === 'selected') {
            this.isLoading = true;
            const payload = {
              limit: this.dataCount,
              page: 1,
              search: this.studentSearchValue,
              filters: this.getSelectedFilters(),
              fields: ['user_id'],
              sort_by: this.sort_by,
              sort_order: this.sort_order,
              group_account_id: this.group_account_id
            };
            const response: any = await this.studentService.fetchStudentDetails(payload);
            if (response.success) {
              const userIdsToRemove = response.data.data?.map((student: any) => student?.user_id);
              this.newlyAddedStudents = this.newlyAddedStudents.filter(userId => !userIdsToRemove.includes(userId));
              this.selectedStudentCount = this.newlyAddedStudents.length;
              this.messageService.add({
                severity: 'info',
                summary: 'All Students Removed',
                detail: 'All students removed from broadcast selection.',
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to remove students.',
              });
            }
            this.isLoading = false;
          } else {
            this.selectedStudents = [];
            this.afterRemovedStudentCount = 0;
            this.messageService.add({
              severity: 'info',
              summary: 'All Students Removed',
              detail: 'All students removed from broadcast selection.',
            });
          }
        }
        if (!this.isEmptyObject(this.selectedChat)) {
          await this.createBroadcast();
        }
      } else if (type === 'removeAll') {
        this.newlyAddedStudents = [];
        this.selectedStudentCount = 0;
        this.tableComponent.resetSelected();
        this.messageService.add({
          severity: 'info',
          summary: 'Broadcast Cleared',
          detail: 'All selected students have been cleared.',
        });
      }
  
      if (this.existingStudentsCount) {
        this.updateStudentTabs({ selected: this.selectedStudentCount, existing: this.existingStudentsCount }, this.tableType === 'selected' ? 0 : 1);
      } else {
        this.updateStudentTabs({ selected: this.selectedStudentCount }, 0);
      }
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'An unexpected error occurred. Please try again.',
      });
      console.error('Error in tableAction:', error);
    }
  }
  
  private getTimestamp(object: any, fieldPath: string): number {
    const fieldParts = fieldPath.split('.');
    const timestampObj = fieldParts.reduce(
      (acc, part) => acc && acc[part],
      object
    );
    if (timestampObj && timestampObj.date && timestampObj.time) {
      return new Date(`${timestampObj.date} ${timestampObj.time}`).getTime();
    }
    return 0;
  }

  async onChatScroll() {
    const scrollTop = this.chatScrollContainer.nativeElement.scrollTop;
    const scrollHeight = this.chatScrollContainer.nativeElement.scrollHeight;
    const containerHeight =
      this.chatScrollContainer.nativeElement.clientHeight + 10;

    if (scrollTop + containerHeight >= scrollHeight && this.hasMoreData) {
      await this.fetchChats();
    }
  }

  async onScroll() {
    const scrollTop = this.scrollContainer.nativeElement.scrollTop;
    const scrollHeight = this.scrollContainer.nativeElement.scrollHeight;
    const containerHeight =
      this.scrollContainer.nativeElement.clientHeight + 10;

    if (scrollTop + containerHeight >= scrollHeight && this.hasMoreData) {
      await this.fetchAllCommunication();
    }
  }

  async onBroadcastScroll() {
    const scrollTop = this.broadcastScrollContainer.nativeElement.scrollTop;
    const scrollHeight =
      this.broadcastScrollContainer.nativeElement.scrollHeight;
    const containerHeight =
      this.broadcastScrollContainer.nativeElement.clientHeight + 10;

    if (scrollTop + containerHeight >= scrollHeight && this.hasMoreData) {
      await this.fetchBroadcasts();
    }
  }

  private scrollToBottom(): void {
    try {
      if (this.userChatContainer) {
        const element = this.userChatContainer.nativeElement;
        this.userChatContainer.nativeElement.scrollTo({
          top: element.scrollHeight,
          behavior: 'instant',
        });
      }
    } catch (err) {
      console.error('Error scrolling to bottom:', err);
    }
  }

  formTemplateDataOptions(type: string) {  
    const startsWithArr = ['common_'];
    if (type === 'chat') {
      startsWithArr.push("singular_");
    } else if (type === 'broadcast') {
      startsWithArr.push("broadcast_");
    }
  
    const res: any = this.templateData
      .filter((temp: any) => startsWithArr.some(prefix => temp?.name?.startsWith(prefix)))
      .map((template: any) => {
        const processedLabel = template.name
          .replace(/^(common_|singular_|broadcast_)/, '')
          .replace(/_/g, ' ')
          .replace(/\b\w/g, (char: any) => char.toUpperCase());
  
        return {
          label: processedLabel,
          value: template,
        };
      });
  
    return res;
  }

  processFetchedChats(chats: any) {
    const nowInSeconds = Math.floor(Date.now() / 1000);
    const last24HoursInSeconds = nowInSeconds - 24 * 60 * 60;

    return chats?.map((chat: any) => {
      let formattedMessage = this.formatMessage(chat?.recent_message);
      let lastMessage = formattedMessage?.header || formattedMessage?.body;

      if (!lastMessage) {
        if (this.fileTypes.includes(formattedMessage?.type?.toLowerCase())) {
          lastMessage = `<i class="pi pi-file"></i> ${this.toTitleCase(
            formattedMessage?.type
          )}`;
        }
      }

      let received_timestamps = chat?.chats?.map(
        (ch: any) => ch?.received_timestamp
      );

      const isActive = received_timestamps?.some((timestamp: number) => {
        return timestamp > last24HoursInSeconds;
      });

      return {
        ...chat,
        lastMessage:
          lastMessage?.length > 45
            ? lastMessage.slice(0, 45) + '...'
            : lastMessage,
        lastTimestamp: formattedMessage?.lastTimestamp,
        isActive: isActive,
      };
    });
  }

  formatMessage(message: any): any {
    if (!message) return {};

    const {
      message_data,
      type,
      received_timestamp,
      delivered_timestamp,
      message_id,
      delivery_status
    } = message;

    let formattedMessage: any = {
      header: '',
      body: '',
      footer: '',
      buttons: [],
      media_url: '',
      type: '',
      fileName: ''
    };

    switch (type?.toLowerCase()) {
      case 'template':
        const template = this.formatTemplateMessage(message_data);
        if (template) {
          formattedMessage.header = template.header;
          formattedMessage.body = template.body;
          formattedMessage.footer = template.footer;
          formattedMessage.buttons = template.buttons;
        }
        else {
          formattedMessage.body = 'No preview available';
        }
        break;

      case 'interactive':
        const interactive = this.formatInteractiveMessage(message_data);
        if (interactive) {
          formattedMessage.body = interactive.body;
          formattedMessage.footer = interactive.footer;
          formattedMessage.buttons = interactive.buttons;
        }
        else {
          formattedMessage.body = 'No preview available';
        }
        break;

      case 'text':
        formattedMessage.body = this.formatTextMessage(message_data);
        break;

      case 'button':
        formattedMessage.body = JSON.parse(message_data)?.button?.text || '';
        break;

      case 'image':
      case 'video':
      case 'document':
      case 'audio':
        const messageData = JSON.parse(message_data);
        const messageType = type.toLowerCase()
        formattedMessage.type = messageType;
        formattedMessage.media_url = messageData?.[messageType]?.[delivery_status === 'Received' ? 'id' :'link'] || '';
        formattedMessage.fileName = delivery_status === 'Received' ? (messageData?.[messageType]?.filename || messageData?.[messageType]?.id) : this.getFileNameFromUrl(messageData?.[messageType]?.['link']);
        break;
      case 'reaction':
        formattedMessage['context'] = { message_id: JSON.parse(message_data)?.message_id };
        formattedMessage.type = type.toLowerCase();
        formattedMessage.body = `Reacted ${JSON.parse(message_data)?.emoji} to your message..` || '';
        break;
      default:
        formattedMessage.body = 'No preview available';
    }

    const messageKeys = Object.keys(
      JSON.parse(this.sanitizeMessageData(message_data))
    );
    const timestamp = received_timestamp || delivered_timestamp;
    const formattedTimestamp = this.formatUnixTimestamp(timestamp, 'custom');
    const direction = received_timestamp ? 'received' : 'delivered';

    return {
      ...formattedMessage,
      lastTimestamp: {
        date: formattedTimestamp.split(', ')[0],
        time: formattedTimestamp.split(', ')[1],
      },
      direction,
      message_id,
      ...(messageKeys.includes('context')
        ? {
            context: JSON.parse(this.sanitizeMessageData(message_data)).context,
          }
        : {}),
    };
  }

  private formatTemplateMessage(templateMessage: any): any {
    try {
      const { template } = JSON.parse(templateMessage);
      const templateDefinition = this.templateData.find(
        (data: any) => data?.name === template.name
      );

      if (!templateDefinition) {
        console.warn(
          `No matching template found for template name: ${template.name}`
        );
        return null;
      }

      const messageParts: any = {
        header: '',
        body: '',
        buttons: [],
      };

      // components with no variables
      if (!template?.components?.length) {
        templateDefinition.components.forEach((defn: any) => {
          if (defn.text && defn?.type !== 'FOOTER') {
            messageParts.body += `${this.boldText(defn.text)}\n\n`;
          }
        });
        return messageParts;
      }

      template.components.forEach((component: any) => {
        switch (component.type) {
          case 'header':
            const headerTemplate = templateDefinition.components.find(
              (comp: any) => comp.type === 'HEADER'
            );
            if (headerTemplate && headerTemplate.text) {
              const formattedHeader = this.applyParameters(
                headerTemplate.text,
                component.parameters
              );
              messageParts.header = this.boldText(formattedHeader);
            }
            break;

          case 'body':
            const bodyTemplate = templateDefinition.components.find(
              (comp: any) => comp.type === 'BODY'
            );
            if (bodyTemplate && bodyTemplate.text) {
              const formattedBody = this.applyParameters(
                bodyTemplate.text,
                component.parameters
              );
              messageParts.body += this.boldText(formattedBody) + '\n\n';
            }
            break;

          //   case 'footer':
          //     const footerTemplate = templateDefinition.components.find(
          //       (comp: any) => comp.type === 'FOOTER'
          //     );
          //     if (footerTemplate && footerTemplate.text) {
          //       messageParts.footer = this.boldText(footerTemplate.text);
          //     }
          //     break;

          case 'button':
            const buttonTemplate = templateDefinition.components.find(
              (comp: any) => comp.type === 'BUTTONS'
            );
            if (buttonTemplate && buttonTemplate.buttons) {
              const button =
                buttonTemplate.buttons[parseInt(component.index || '0')];
              if (button) {
                let formattedUrl = button.url || '';
                component.parameters?.forEach((param: any) => {
                  formattedUrl = formattedUrl.replace('{{1}}', param.text);
                });
                messageParts.buttons.push({
                  text: button.text,
                  url: formattedUrl,
                });
              }
            }
            break;

          default:
            console.warn(`Unknown component type: ${component.type}`);
        }
      });

      return messageParts;
    } catch (error) {
      console.error('Error formatting template message:', error);
      return null;
    }
  }

  private formatInteractiveMessage(interactiveMessage: any): any {
    let messageParts: any = {
      body: '',
      buttons: [],
    };

    const sanitizedMessageData = this.sanitizeMessageData(interactiveMessage);

    try {
      const interactiveData = JSON.parse(sanitizedMessageData);

      if (interactiveData) {
        messageParts.body =
          this.boldText(
            interactiveData.interactive?.body?.text ||
              `${interactiveData.interactive?.list_reply?.title}<br/>${interactiveData.interactive?.list_reply?.description}`
          ) || '';

        const buttonLabel = interactiveData.interactive.action?.button || '';
        const sections = interactiveData.interactive.action?.sections || [];

        sections.forEach((section: any) => {
          section.rows.forEach((row: any) => {
            messageParts.buttons.push({
              text: row.title,
              description: row.description,
            });
          });
        });

        messageParts.body += `<br/><br/>${buttonLabel}`;
        return messageParts;
      }
    } catch (error) {
      console.error('Failed to parse message_data:', error);
    }

    return messageParts;
  }

  private formatTextMessage(message_data: string): string {
    try {
      // replacing newline characters with HTML breaks
      const cleanedData = this.sanitizeMessageData(message_data);

      const parsedData = JSON.parse(cleanedData);

      return this.boldText(
        parsedData?.text?.body
          ? this.sanitizeMessageData(parsedData.text.body)
          : ''
      );
    } catch (error) {
      console.error('Failed to parse message_data:', error);
      return '';
    }
  }

  private applyParameters(text: string, parameters: any[]): string {
    let formattedText = text;
    parameters.forEach((param: any, index: number) => {
      formattedText = formattedText.replace(`{{${index + 1}}}`, param.text);
    });
    return formattedText;
  }

  public getOriginalMessage(originalMessageId: string): any {
    for (const group of this.selectedChatConversations?.chats) {
      const originalMessage = group.messages.find(
        (msg: any) => msg.message_id === originalMessageId
      );

      if (originalMessage) {
        const messageBody = originalMessage?.body
          ? this.boldText(originalMessage.body.substring(0, 250)) +
            (originalMessage.body?.length > 250 ? '.....' : '')
          : `<i class="pi ${this.getFileIconClass(
              originalMessage.type
            )}"></i> ${this.toTitleCase(originalMessage?.type)}`;
        return messageBody;
      }
    }
    return 'Message might be deleted or not found';
  }

  public boldText(text: string): string {
    return text.replace(/\*(.*?)\*/g, '<strong>$1</strong>');
  }

  private formatUnixTimestamp(
    unixTimestamp: number,
    formatType: 'standard' | 'custom' = 'standard'
  ): string {
    const date = new Date(unixTimestamp * 1000);

    const utcDate = new Date(
      Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
      )
    );

    if (formatType === 'custom') {
      return new Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      }).format(utcDate);
    } else {
      return utcDate.toLocaleString('en-US');
    }
  }

  public async openChat(chat: any) {
    this.showDropdown = false;
    // if (this.fetchInterval) {
      //   clearInterval(this.fetchInterval);
      // }
      
      if (chat?.mobile_number === this.selectedChat?.mobile_number) {
        return;
      }
      
      this.isChatLoading = true;
      this.replyContext = null;
      this.selectedChat = chat;
      this.templateDataOptions = this.formTemplateDataOptions('chat');
    await this.updateCurrentConversation();
    // this.selectedChatConversations = {
    //   chats: this.formatForChatView(this.selectedChat?.chats),
    //   profile_picture: this.selectedChat?.profile_picture,
    // };

    this.isChatLoading = false;
    this.cdr.detectChanges();
    this.scrollToBottom();
    // this.fetchInterval = setInterval(async () => {
    //   await this.updateCurrentConversation();
    // }, 15000);
  }

  openDialog(mode: 'edit' | 'delete') {
    this.dialogMode = mode;
    this.dialogHeader = mode === 'edit' ? 'Edit Broadcast Name' : 'Delete Broadcast';
    this.showDropdown = false;
    this.showConfirmDialog = true;  
    this.broadcastName = mode === 'edit' ? this.selectedChat?.name : '';
    this.confirmationInput = '';
  }
  
  async confirmEdit() {
    try {
      if (!this.broadcastName || this.broadcastName.trim() === '') {
        console.error('Broadcast name is required');
        return;
      }

      this.isLoading = true;
      const payload = {
        broadcast_id: this.selectedChat.broadcast_id,
        broadcast_name: this.broadcastName.trim(),
      };
      this.showConfirmDialog = false;

      const res: any = await this.chatService.updateBroadcast(payload);

      if (res && res.success) {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Broadcast name updated successfully.',
        });
        this.selectedChat.name = this.broadcastName;
        // this.resetStates();
        this.isLoading = false;
        if (this.chatType === 'broadcast') {
          this.broadcastList = [];
          await this.fetchBroadcasts(true);
        }
        else {
          this.allChatList = [];
          await this.fetchAllCommunication(true);
        }
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error while updating broadcast name.',
        });
      }
    } catch (error) {
      console.error('An error occurred while updating the broadcast name', error);
    }
    finally {
      this.broadcastName = '';
    }
  }

  async confirmDelete() {
    try {
      this.isLoading = true;
      const res: any = await this.chatService.deleteBroadcast(this.selectedChat.broadcast_id);
      if (res && res.success) {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Broadcast deleted successfully.',
        });
        this.showConfirmDialog = false;
        this.resetStates();
        if (this.chatType === 'broadcast') {
          this.broadcastList = [];
          await this.fetchBroadcasts(true);
        }
        else {
          this.allChatList = [];
          await this.fetchAllCommunication(true);
        }
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error while deleting broadcast.',
        });
      }
    } catch (error) {
      console.error('An error occurred while deleting the broadcast', error);
    } finally {
      this.isLoading = false;
    }
  }
  
  public openChatDetails(chat: any) {
    this.showDropdown = false
    this.selectedChatDetail = {
      chat: {
        ...chat,
        ...(chat?.data ? { data: JSON.parse(chat?.data) } : {}),
      },
      broadcast: this.broadcastInfo,
    };
    this.showSidebar = true;
  }

  getLabel(type: string): string {
    switch (type) {
      case 'drives':
        return 'Drive';
      case 'manual':
        return 'Manual';
      case 'campus_batch':
        return 'Campus  |  Passout Year';
      case 'campus_batch_programme':
        return 'Campus  |  Department  |  Degree  |  Specialization  |  Passout Year';
    }
    return 'Type';
  }

  getValue(obj: any): string {
    switch (obj?.type) {
      case 'drives':
        return obj?.drive_name;
      case 'campus_batch_programme':
        return `${obj?.campus}  |  ${obj?.department}  |  ${obj?.degree}  |  ${obj?.programme}  |  ${obj?.data?.pass_out_year}`;
      case 'campus_batch':
        return `${obj?.campus}  |  ${obj?.data?.pass_out_year}`;
      case 'manual':
        return `${this.broadcastInfo?.length} Users`;
    }
    return '';
  }

  getProfileImage(): string {
    if (this.chatType === 'broadcast' || (this.chatType === 'all' && this.selectedChat?.broadcast_id)) {
      return broadcastProfileIcon;
    }
    if (this.chatType === 'chats' || this.chatType === 'all') {
      return this.selectedChat?.profile_picture || chatProfileIcon;
    }
    return chatProfileIcon;
  }  

  public clearInputs() {
    this.showSidebar = false;
    this.selectedChatDetail = {};
  }

  public async openBroadcast(chat: any) {
    try {
      this.showDropdown = false;
      this.isChatLoading = true;
      this.replyContext = null;
      this.selectedChat = chat;
      this.broadcastInfo = null;
      this.selectedChatConversations = null;
      this.templateDataOptions = this.formTemplateDataOptions('broadcast');
      if (!chat || !chat.broadcast_id) {
        this.messageService.add({
          severity: Severity.ERROR,
          summary: Summary.ERROR,
          detail: 'Invalid chat data. Broadcast ID is missing.',
        });
        return;
      }
  
      const res: any = await this.chatService.fetchBroadcastInfo(chat?.broadcast_id, {
        data: this.safeParseJSON(chat?.data),
        type: chat?.type,
      });
  
      if (!res || !res.success) {
        this.messageService.add({
          severity: Severity.ERROR,
          summary: Summary.ERROR,
          detail: res?.message || 'Error opening broadcast, Try again later.',
        });
        return;
      }
  
      const broadcastInfo = res.data;
  
      if (!broadcastInfo?.studentData || !broadcastInfo?.chats) {
        throw new Error('Incomplete broadcast details received.');
      }
  
      this.broadcastInfo = broadcastInfo.studentData;
      this.selectedChatConversations = {
        chats: this.formatForChatView(broadcastInfo.chats),
        profile_picture: broadcastProfileIcon,
        type: 'broadcast',
      };
    } catch (error) {
      console.error('Error while fetching broadcast details:', error);  
      this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: 'Something went wrong. Please try again later.',
      });
    } finally {
      this.isChatLoading = false;
      this.cdr.detectChanges();
      this.scrollToBottom();
    }
  }

  private async updateCurrentConversation() {
    try {
      if (!this.selectedChat || !this.selectedChat.mobile_number) {
        console.warn('No chat selected or mobile number is missing.');
        return;
      }
  
      const res: any = await this.chatService.fetchChats({
        mobile_number: this.selectedChat.mobile_number,
        type: 'chats',
      });
  
      if (!res || !res.success || !res.data) {
        console.error('Error fetching chat data:', res?.message || 'Unknown error');
        this.messageService.add({
          severity: Severity.ERROR,
          summary: Summary.ERROR,
          detail: 'Failed to update conversation. Please try again later.',
        });
        return;
      }
  
      const formattedChat = this.processFetchedChats(res.data.data);
      const chatsData = this.formatForChatView(formattedChat?.[0]?.chats);
  
      this.selectedChatConversations = {
        chats: chatsData || [],
        profile_picture: this.selectedChat?.profile_picture || '',
        type: 'chat',
      };
  
      this.cdr.detectChanges();
      this.scrollToBottom();
    } catch (error) {
      console.error('An error occurred while updating the conversation:', error);  
      this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: 'Something went wrong. Please refresh and try again.',
      });
    }
  }
  
  private formatForChatView(chats: any[]): any[] {
    const groupedChats: any[] = [];

    const sortedChats = chats?.sort((a, b) => {
      const aTimestamp = a.received_timestamp || a.delivered_timestamp;
      const bTimestamp = b.received_timestamp || b.delivered_timestamp;
      return parseInt(aTimestamp) - parseInt(bTimestamp);
    });

    sortedChats?.forEach((chat) => {
      const formattedMessage = this.formatMessage(chat);

      const date = formattedMessage.lastTimestamp.date;

      const dateGroup = groupedChats?.find((group) => group.date === date);
      if (dateGroup) {
        dateGroup.messages.push(formattedMessage);
      } else {
        groupedChats.push({
          date,
          messages: [formattedMessage],
        });
      }
    });

    return groupedChats;
  }

  async searchValueChange(event: any) {
    if (this.isChatScrolling || this.isBroadcastScrolling || this.isAllScrolling) {
      return;
    }
  
    try {
      this.searchValue = event.target.value;
      this.hasMoreData = true;
      this.selectedChat = {};
      this.selectedChatConversations = [];
      this.currentBroadcastScroll = 0;
      this.currentChatScroll = 0;
      this.currentAllScroll = 0;
      this.isChangeDisabled = true;
      this.updateTabDisability();
  
      const commonParams = {
        search: this.searchValue || undefined,
        offset: 0,
        limit: this.limit,
      };
  
      if (this.chatType === 'chats') {
        await this.handleChatSearch(commonParams);
      } else if (this.chatType === 'broadcast') {
        await this.handleBroadcastSearch(commonParams);
      } else {
        await this.handleAllChatSearch(commonParams);
      }
    } catch (error) {
      console.error('Error in searchValueChange:', error);
    } finally {
      this.isChangeDisabled = false;
      this.updateTabDisability();
    }
  }
  
  private async handleChatSearch(params: any) {
    try {
      this.chatList = [];
      this.isChatScrolling = true;

      const res: any = await this.chatService.fetchChats({ ...params, type: 'chats' });
  
      if (!res?.success) throw new Error('Failed to fetch chats.');

      this.hasMoreData = res.data?.hasMoreData;
      this.chatList = this.sortArrayByTimestamp(
        this.processFetchedChats(res?.data?.data),
        'lastTimestamp',
        'desc'
      );
    } catch (error) {
      console.error('Error fetching chats:', error);
      this.chatList = [];
    } finally {
      this.isChatScrolling = false;
    }
  }
  
  private async handleBroadcastSearch(params: any) {
    this.broadcastList = [];
    this.isBroadcastScrolling = true;
  
    try {
      const res: any = await this.chatService.fetchChats({ ...params, type: 'broadcasts' });
  
      if (!res?.success) throw new Error('Failed to fetch broadcasts.');
  
      if (res?.data?.length < this.limit) {
        this.hasMoreData = false;
      }

      this.broadcastList = this.sortArrayByTimestamp(
        this.processFetchedChats(res?.data),
        'lastTimestamp',
        'desc'
      );
    } catch (error) {
      console.error('Error fetching broadcasts:', error);
      this.broadcastList = [];
    } finally {
      this.isBroadcastScrolling = false;
    }
  }
  
  private async handleAllChatSearch(params: any) {
    this.allChatList = [];
    this.isAllScrolling = true;
  
    try {
      const res: any = await this.chatService.fetchChats({ ...params, type: 'all' });
  
      if (!res?.success) throw new Error('Failed to fetch all chats.');
  
      this.hasMoreData = res?.data?.hasMoreBroadcasts || res?.data?.hasMoreChats;
      this.allChatList = this.sortArrayByTimestamp(
        this.processFetchedChats([...res?.data?.broadcasts, ...res?.data?.chats]),
        'lastTimestamp',
        'desc'
      );
    } catch (error) {
      console.error('Error fetching all chats:', error);
      this.allChatList = [];
    } finally {
      this.isAllScrolling = false;
    }
  }

  async chatTypeChange(event: any) {
    if (this.isChangeDisabled) {
      return;
    }

    // disabling tab changes
    this.isChangeDisabled = true;
    this.updateTabDisability();

    try {
      this.chatType = event.value;

      this.resetStates();

      if (this.chatType === 'chats') {
        await this.fetchChats();
      } else if (this.chatType === 'broadcast') {
        await this.fetchBroadcasts();
      } else {
        await this.fetchAllCommunication();
      }
    } catch (error) {
      console.error('An error occurred while changing the chat type:', error);
    } finally {
      this.isChangeDisabled = false;
      this.updateTabDisability();
    }
  }

  resetStates() {
    this.isAllScrolling = false;
    this.isChatScrolling = false;
    this.isBroadcastScrolling = false;
    this.selectedChat = {};
    this.selectedChatConversations = {};
    this.hasMoreData = true;
    this.currentBroadcastScroll = 0;
    this.currentChatScroll = 0;
    this.currentAllScroll = 0;
    this.chatList = [];
    this.allChatList = [];
    this.broadcastList = [];
    this.broadcastInfo = [];
    this.searchPlaceholders =
      this.chatType === 'broadcast'
        ? 'Broadcast Name'
        : this.chatType === 'all'
        ? 'Broadcast Name, Email, Registration No'
        : 'Email, Registration No';
    this.openNewBroadcast = false;
    this.searchValue = '';
    this.studentSearchValue = '';
    this.templateDataOptions = [];
  }

  updateTabDisability() {
    this.chatTypeOptions = this.chatTypeOptions.map(option => ({
      ...option,
      disabled: this.isChangeDisabled
    }));
  }
  
  async newBroadcast() {
    this.selectedChat = {};
    this.broadcastInfo = [];
    this.selectedChatConversations.chats  = [];
    // this.tabItems.push({ label: `Selected Students (${this.selectedStudentCount})`, icon: 'pi pi-user-plus' });
    // this.activeItem = this.tabItems[0];
    this.actionOptions.push(
      {
        item_name: 'Add Students', type: 'addToBroadcast', item_type: 'button'
      },
      {
        item_name: 'Remove Students', type: 'removeFromBroadcast', item_type: 'button'
      },
      {
        item_name: 'Remove All', type: 'removeAll', item_type: 'button'
      });
    this.openNewBroadcast = true;
    await this.fetchStudents();
    this.updateStudentTabs({ selected: 0 }, 0);
  }

  async openAddStudentsSidebar() {
    try {
      this.selectedStudents = [];
      this.tabItems = [];
      this.selectedStudentCount = 0;
      this.isBroadcastStep2 = false;
      this.tableType = 'selected';
      
      this.actionOptions.push({
        item_name: 'Add Students',
        type: 'addToBroadcast',
        item_type: 'button'
      });
      
      this.openNewBroadcast = true;
  
      try {
        const parsedData = JSON.parse(this.selectedChat.data);
        this.selectedStudents = parsedData.users || [];
      } catch (error) {
        console.error("Error parsing selectedChat data:", error);
        this.selectedStudents = [];
      }
  
      this.tabItems.push(
        { label: `Select Students`, icon: 'pi pi-user-plus', value: 'selected' }
      );
  
      if (this.selectedStudents.length) {
        this.tabItems.push(
          { label: `Existing Students (${this.broadcastInfo.length})`, icon: 'pi pi-users', value: 'existing' }
        );
      }
  
      this.activeItem = this.tabItems[0];
      this.existingStudentsCount = this.selectedStudents.length;
  
      try {
        await this.fetchStudents();
        this.updateStudentTabs({ selected: 0, existing: this.broadcastInfo.length }, 0);
      } catch (error) {
        console.error("Error fetching students:", error);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to fetch students. Please try again later.'
        });
      }
  
      if (this.tableComponent) {
        this.tableComponent.resetSelected();
      } else {
        console.warn("Table component is not initialized.");
      }
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to open the sidebar. Please try again later.'
      });
    }
  }  

  updateStudentTabs(data: any, highlightIndex: number) {
    this.tabItems = [];
    if (Object.keys(data).includes('selected')) {
      if (this.isEmptyObject(this.selectedChat)) {
        this.tabItems.push({ label: `Selected Students (${this.selectedStudentCount})`, icon: 'pi pi-user-plus', value: 'selected' })
      }
      else {
        this.tabItems.push({ label: `Select Students`, icon: 'pi pi-user-plus', value: 'selected' })
      }
    }
    if (Object.keys(data).includes('existing')) {
      this.tabItems.push(
        { label: `Existing Students (${data.existing})`, icon: 'pi pi-users', value: 'existing' }
      )
    }
    this.activeItem = this.tabItems[highlightIndex];
  }

  private getSelectedFilters() {
    const { campus: selectedCampusIds, department: selectedDepartmentIds, degree: selectedDegreeSpec } = this.selectedFilters;
    const selectedFilters = { ...this.selectedFilters };
    const selectedCampuses = new Set(selectedCampusIds);
    const selectedDepartments = new Set(selectedDepartmentIds);
    const selectedDegrees = new Set(selectedDegreeSpec.map((each: any) => `${each.degree_id}-${each.programme_id}`));
    const isCampusSelected = selectedCampuses.size > 0;
    const isDepartmentSelected = selectedDepartments.size > 0;
    selectedFilters.department = [];
    selectedFilters.degree = [];
    selectedFilters.profileStatus = [];
    selectedFilters.platformStatus = [];
    selectedFilters.driveStatus = [];
    selectedFilters.interestedForPlacement = [];
    selectedFilters.mobileVerified = ['VERIFIED'];

    this.campusDetails.forEach(each => {
      const campusMatch = selectedCampuses.has(each.account_id);
      const departmentMatch = selectedDepartments.has(each.department_id);
      const degreeMatch = selectedDegrees.has(`${each.degree_id}-${each.programme_id}`);

      // Add to department if campus or department matches
      if ((!isCampusSelected && departmentMatch) || (campusMatch && departmentMatch)) {
        selectedFilters.department.push({
          account_id: each.account_id,
          department_id: each.department_id
        });
      }

      // Add to degree if the corresponding conditions match
      if (
        (isCampusSelected && isDepartmentSelected && campusMatch && departmentMatch && degreeMatch) ||
        (!isCampusSelected && isDepartmentSelected && departmentMatch && degreeMatch) ||
        (isCampusSelected && !isDepartmentSelected && campusMatch && degreeMatch) ||
        (!isCampusSelected && !isDepartmentSelected && degreeMatch)
      ) {
        selectedFilters.degree.push({
          account_id: each.account_id,
          department_id: each.department_id,
          degree_id: each.degree_id,
          programme_id: each.programme_id
        });
      }
    });

    return selectedFilters;
  };

  resetFilter()
  {
    this.selectedFilters.campus = [];
    this.selectedFilters.department = [];
    this.selectedFilters.pass_out_year = [];
    this.selectedFilters.degree = [];
    this.totalPage = 0;
    this.rowsize = 10;
    this.selectedPage = 1;
    this.sort_by = '';
    this.sort_order = 2;
  }

  confirmBroadcast() {
    this.tableComponent.resetSelected();
    this.isBroadcastStep2 = true;
  }

  async createBroadcast() {
    const isAddNewStudents = !this.isEmptyObject(this.selectedChat);
    this.isLoading = true;

    if (isAddNewStudents) {
      try {
        const payload: any = {
          broadcast_id: this.selectedChat.broadcast_id,
          users: [...this.newlyAddedStudents, ...this.selectedStudents]
        };

        let res: any = await this.chatService.updateBroadcast(payload);
        if (res) {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Broadcast users updated successfully',
          });
          const res: any = await this.chatService.fetchBroadcastInfo(this.selectedChat.broadcast_id, {type: 'manual'});
  
          if (!res || !res.success) {
            this.messageService.add({
              severity: Severity.ERROR,
              summary: Summary.ERROR,
              detail: res?.message || 'Error opening broadcast, Try again later.',
            });
            return;
          }
      
          const broadcastInfo = res.data;
          if (!broadcastInfo?.studentData || !broadcastInfo?.chats) {
            throw new Error('Incomplete broadcast details received.');
          }
      
          this.broadcastInfo = broadcastInfo.studentData;
          this.cdr.detectChanges()
          // this.resetStates();
          this.showSidebar = false;
          // if (this.chatType === 'broadcast') {
          //   this.broadcastList = [];
          //   await this.fetchBroadcasts(true);
          // }
          // else {
          //   this.allChatList = [];
          //   await this.fetchAllCommunication(true);
          // }
        } else {
          this.messageService.add({
            severity: Severity.WARN,
            summary: Summary.WARNING,
            detail: 'Error adding users to the broadcast, Try again later.',
          });
        }
      } catch (error) {
        console.error('Error while adding students to broadcast:', error);
        this.messageService.add({
          severity: Severity.ERROR,
          summary: 'Error',
          detail: 'An unexpected error occurred. Please try again later.',
        });
      }
    } else {
      const payload: any = {
        name: this.broadcastName,
        type: 'manual',
        data: {
          users: this.newlyAddedStudents
        }
      };

      let res: any = await this.chatService.createBroadcasts(payload);
      if (res.success) {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Broadcast created successfully.',
        });
        this.resetStates();
        if (this.chatType === 'broadcast') {
          this.broadcastList = [];
          await this.fetchBroadcasts(true);
        }
        else {
          this.allChatList = [];
          await this.fetchAllCommunication(true);
        }
      } else {
        this.messageService.add({
          severity: Severity.WARN,
          summary: Summary.WARNING,
          detail: 'Error creating broadcasts, Try again later.',
        });
      }
    }
    this.broadcastName = '';
    this.onBroadcastDialogClose();
    this.openNewBroadcast = false;
    this.isBroadcastStep2 = false;
    this.isLoading = false;
  }

  async onBroadcastTypeChange() {
    this.isLoading = true;

    switch (this.broadcastBy) {
      case 'drives':
        const drivePayload = {
          group_account_id: this.group_account_id,
          search: '',
          limit: 10,
          page: 1,
          sort_by: 'lastDate',
          sort_order: 1,
          fields: ['name', 'drive_id'],
          filters: {
            drive_status: ['Upcoming'],
            campus: [],
            degree: [],
            department: [],
            pass_out_year: [],
            createdAt: [],
          },
        };
        let driveRes: any = await this.chatService.getDrives(drivePayload);

        this.broadcastCreateOptions = driveRes?.data?.data?.map(
          (drive: any) => ({
            label: drive?.name,
            value: drive?.drive_id,
          })
        );
        break;

      case 'degree_spec':
        let res: any = await this.globalService.fetchCampusDetails(
          this.group_account_id
        );

        this.broadcastCreateOptions = res?.data?.map((data: any) => ({
          label: `${data?.degree_name} - ${data?.specialization}`,
          value: `${data?.degree_id}_${data?.programme_id}`,
        }));
        break;

      case 'passout_year':
        this.broadcastCreateOptions = this.passoutYearsOptions;
        break;

      default:
        this.broadcastCreateOptions = [];
        break;
    }

    this.isLoading = false;
  }

  getDropdownLabel(broadcastType: string): string {
    switch (broadcastType) {
      case 'drives':
        return 'Select Drive';
      case 'degree_spec':
        return 'Select Degree and Pass out Year';
      case 'passout_year':
        return 'Select Passout Year';
      default:
        return 'Select Option';
    }
  }

  onBroadcastDialogClose() {
    this.broadcastName = '';
    this.broadcastBy = '';
    this.selectedBroadcastType = '';
    this.broadcastCreateOptions = [];
    this.selectedStudents = [];
    this.selectedStudentCount = 0;
    this.existingStudentsCount = 0;
    this.tabItems = [];
    // this.tableComponent.resetSelected();
    this.isBroadcastStep2 = false;
    this.isUsersModalVisible = false;
    this.filteredUsers = [];
    this.studentSearchValue = '';
    this.actionOptions = [];
    this.newlyAddedStudents = [];
    this.afterRemovedStudentCount = 0;
    this.resetFilter();
  }

  formatSingleMessage(message: string, recent_message?: any): SafeHtml {
    const type = recent_message?.type;
    const maxLength = 20;
    const formattedMessage =
      message && message.length > maxLength && (type && !this.fileTypes.includes(type?.toLowerCase()))
        ? message.substring(0, maxLength) + '...'
        : message || '--';

    return this.sanitizer.bypassSecurityTrustHtml(formattedMessage);
  }

  isEmptyObject(obj: any): boolean {
    return obj && Object.keys(obj).length === 0;
  }

  toggleFileOptions() {
    this.showFileOptions = !this.showFileOptions;
  }

  selectFileType(type: string) {
    this.showFileOptions = false;
    this.selectedFileType = type;

    switch (type) {
      case 'image':
        this.imageInput.nativeElement.click();
        break;
      case 'video':
        this.videoInput.nativeElement.click();
        break;
      case 'document':
        this.documentInput.nativeElement.click();
        break;
    }
  }

  async sendMessage() {
    if ((!this.messageText.trim() && this.filePreviews.length === 0) || this.sendingMessage) return;

    this.sendingMessage = true;
    let fileUrls: string[] = [];

    for (const preview of this.filePreviews) {
      this.isFileUploading = true;
      const filePath = await this.uploadFileForWhatsAppMessage(
        preview.file,
        preview.fileType
      );
      if (filePath) {
        fileUrls.push(filePath);
      }
      this.isFileUploading = false;
    }

    const messagePayload: any = {
      group_account_id: this.group_account_id,
      userData: [
        {
          user_id: this.selectedChat?.user_id,
          mobile_number: this.selectedChat?.mobile_number,
          mobile_no_verified: this.selectedChat?.mobile_no_verified,
        },
      ],
      template_content: this.messageText,
      context: {
        message_id: this.replyContext,
      },
      custom_chat: true,
    };

    if (fileUrls.length > 0) {
      messagePayload.media_url = `${this.globalService.prefix}/${this.globalService.publicBucket}/${fileUrls[0]}`;
      messagePayload.media_type = this.selectedFileType;
      messagePayload.media_filename = this.filePreviews[0]?.fileName;
    }

    const response: any = await this.globalService.sendWhatsappNotification(
      messagePayload
    );

    if (response?.success) {
      const newMessage = this.createMessageObject(messagePayload, 'delivered');
      this.selectedFileType = '';
      if (this.isMultiline) this.isMultiline = !this.isMultiline;
      this.updateToCurrentConversation(newMessage);
      this.replyContext = null;
      this.cdr.detectChanges();
      this.scrollToBottom();
    }

    this.messageText = '';
    this.filePreviews = [];
    this.sendingMessage = false;
  }

  createMessageObject(payload: any, direction: string, type?: any) {
    const now = new Date();
    type = type ?? (payload?.media_type ? payload?.media_type : 'text');
    return {
      ...(payload?.media_url ? {
        media_url: payload?.media_url,
        fileName: this.getFileNameFromUrl(payload?.media_url)
      } : {}),
      ...(payload?.media_type ? { type: payload?.media_type } : {}),
      header: '',
      body: payload?.template_content,
      buttons: [],
      lastTimestamp: {
        date: now.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
        }),
        time: now.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
        }),
      },
      direction,
      type,
      message_id: '',
      ...(this.replyContext?.length
        ? { context: { message_id: this.replyContext } }
        : {}),
    };
  }

  toggleMultiline() {
    this.isMultiline = !this.isMultiline;
  }

  handleKeyDown(event: KeyboardEvent, inputType: 'single' | 'multiline') {
    if (event.key === 'Enter') {
      if (inputType === 'single') {
        event.preventDefault();
        this.sendMessage();
      } else if (inputType === 'multiline') {
        if (event.shiftKey || !event.shiftKey) {
          event.preventDefault();
          this.messageText += '\n';
        }
      }
    }
  }

  autoResize(textarea: HTMLTextAreaElement) {
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  setReplyContext(messageId: string) {
    this.replyContext = messageId;
    this.showMessageOptions = '';
  }

  clearReplyContext() {
    this.replyContext = null;
  }

  toggleMessageOptions(messageId: string) {
    this.showMessageOptions =
      this.showMessageOptions === messageId ? null : messageId;
  }

  sanitizeMessageData(message: string) {
    return message
      .replace(/\\n/g, '</br>')
      .replace(/\\r/g, '')
      .replace(/\n/g, '</br>')
      .replace(/\r/g, '');
  }

  private async uploadFileForWhatsAppMessage(
    file: File,
    fileType: string
  ): Promise<string | false> {
    try {
      const type: string = file.type;
      const blobData: Blob = new Blob([file], { type });
      const timestamp = new Date().toISOString().replace(/[-:.TZ]/g, '');
      const cleanFileName = file.name.replace(/\s+/g, '_');

      const pay = {
        bucketName: this.globalService.publicBucket,
        fileName: `whatsapp_files/${this.group_account_id}/${this.selectedChat?.user_id}/${timestamp}_${cleanFileName}`,
        type: type,
      };

      const s3SignedUrl = await this.globalService.getSignedUploadUrl(pay);

      const s3Response: any = await this.fileUploadService.uploadUsingSignedUrl(
        s3SignedUrl.data.response,
        blobData
      );

      if (s3Response && s3Response.status === 200) {
        return pay.fileName;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      return false;
    }
  }

  handleFileUpload(event: Event, fileType: string) {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      const maxFileSize = this.getMaxFileSize(fileType);
      if (file.size > maxFileSize) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Too Large',
          detail: `The selected file exceeds the maximum size of ${this.docMaxSize[fileType]}. Please select a smaller file.`
        })
        return;
      }

      this.selectedFile = file;
      this.selectedFileType = fileType;
      this.filePreviews.push({
        file,
        fileType,
        fileName: file.name.replaceAll(' ', '_'),
      });
    }
  }
  
  getMaxFileSize(fileType: string): number {
    switch (fileType) {
      case 'document':
        return 100 * 1024 * 1024; // 100 MB
      case 'image':
        return 5 * 1024 * 1024; // 5 MB
      case 'video':
        return 16 * 1024 * 1024; // 16 MB
      default:
        return 5 * 1024 * 1024;
    }
  }

  removeSelectedFile() {
    this.selectedFile = null;
    this.selectedFileType = null;
  }

  removeFilePreview(preview: {
    file: File;
    fileType: string;
    fileName: string;
  }) {
    this.filePreviews = this.filePreviews.filter((p) => p !== preview);
  }

  getFileIconClass(fileType: string): string {
    switch (fileType) {
      case 'image':
        return 'pi pi-image';
      case 'video':
        return 'pi pi-video';
      case 'document':
        return 'pi pi-file';
      case 'audio':
        return 'pi pi-microphone';
      default:
        return 'pi pi-file';
    }
  }

  isMediaType(type: string): boolean {
    return this.fileTypes.includes(type?.toLowerCase());
  }

  getFileNameFromUrl(url: string): string {
    return (
      url.split('/').pop()?.replace('_', '$#$').split('$#$')[1] || 'download'
    );
  }

  truncateFileName(fileName: string, limit: number): string {
    return fileName.length > limit
      ? `${fileName.slice(0, limit)}...`
      : fileName;
  }

  downloadFile(message: any): void {
    if (message?.direction === 'received') {
      this.downloadUserFile(message?.media_url);
    }
    else {
      const { media_url: url } = message;
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = this.getFileNameFromUrl(url);
          link.click();
          URL.revokeObjectURL(blobUrl);
        })
        .catch((error) => console.error('Download failed:', error));
    }
  }

  async downloadUserFile(mediaId: string): Promise<void> {
    try {
      const { blob, contentType } = await this.chatService.getUserSentFile(mediaId);
      const fileBlob = new Blob([blob?.data || blob], { type: contentType });  
      const extensionMap: { [key: string]: string } = {
        'application/pdf': '.pdf',
        'image/jpeg': '.jpg',
        'image/png': '.png',
        'image/gif': '.gif',
        'text/plain': '.txt',
        'application/json': '.json',
        'application/msword': '.doc',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
        'application/excel': '.xls',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
        'audio/mpeg': '.mp3',
        'audio/ogg': '.mp3',
        'video/mp4': '.mp4',
        'application/zip': '.zip'
      };
  
      const extension = extensionMap[contentType] || '.bin';
      const filename = `file_${mediaId}${extension}`;
  
      const downloadURL = URL.createObjectURL(fileBlob);
      const anchor = document.createElement('a');
      anchor.href = downloadURL;
      anchor.download = filename;
      
      document.body.appendChild(anchor);
      anchor.click();      
      document.body.removeChild(anchor);
      URL.revokeObjectURL(downloadURL);
  
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  }

  toTitleCase(text: string): string {
    return text
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  showDialog(buttonElement: any) {
    const rect = buttonElement.nativeElement.getBoundingClientRect();

    this.dialogStyle = {
      width: '35vw',
      position: 'fixed',
      top: `${rect.top - 220}px`,
      left: `${rect.left}px`,
    };

    this.showTemplateDialog = true;
  }

  selectTemplate(template: any) {
    this.selectedTemplate = template;
    this.placeholders = this.getPlaceholders(
      template.components.find((c: any) => c.type === 'BODY')?.text || ''
    ).map(() => '');
  }

  getPlaceholders(text: string): string[] {
    const regex = /{{\d+}}/g;
    return text.match(regex) || [];
  }

  hasPlaceholders(text: string): boolean {
    return /{{\d+}}/.test(text);
  }

  getPreview(template: any): string {
    let previewText = '';

    template.components.forEach((component: any) => {
      let componentText = '';

      if (component?.type !== 'FOOTER') {
        componentText = component.text || '';
      }

      const placeholders =
        component.type === 'HEADER'
          ? this.headerPlaceholders
          : component.type === 'BODY'
          ? this.bodyPlaceholders
          : component.type === 'BUTTON'
          ? this.buttonPlaceholders
          : [];

      placeholders.forEach((value: any, index: any) => {
        componentText = componentText.replace(
          `{{${index + 1}}}`,
          value || `{{${index + 1}}}`
        );
      });

      previewText += `<div>${this.boldText(componentText)}</div>`;
    });

    return previewText;
  }

  async sendTemplateMessage(template: any, overlayPanelRef: any) {
    if (!this.validateTemplatePlaceholders()) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please fill in all required placeholders.',
      });
      return;
    }

    this.isLoading = true;
    const payload = {
      type: this.selectedChat?.broadcast_id ? 'broadcast' : 'chats',
      templateName: template.name,
      headerPlaceholders: this.headerPlaceholders,
      buttonPlaceholders: this.buttonPlaceholders,
      bodyPlaceholders: this.bodyPlaceholders,
      userData: {
        mobile_number: [this.selectedChat?.mobile_number],
        user_id: this.selectedChat?.user_id,
      },
      broadcast_id: this.selectedChat?.broadcast_id,
      broadcast_type: this.selectedChat?.type,
      broadcast_data: this.selectedChat?.data
        ? JSON.parse(this.selectedChat?.data)
        : {},
    };

    try {
      const res: any = await this.chatService.sendTemplateMessage(payload);
      let messagePayload: any;

      if (res.success) {
        overlayPanelRef.hide();
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Message sent successfully.',
        });

        if (this.chatType === 'chats') {
          messagePayload = {
            group_account_id: this.group_account_id,
            userData: [
              {
                user_id: this.selectedChat?.user_id,
                mobile_number: this.selectedChat?.mobile_number,
                mobile_no_verified: this.selectedChat?.mobile_no_verified,
              },
            ],
            template_content: this.getPreview(this.selectedTemplate),
            context: {
              message_id: this.replyContext,
            },
            custom_chat: true,
          };
        } else {
          messagePayload = {
            group_account_id: this.group_account_id,
            template_content: this.getPreview(this.selectedTemplate),
            broadcast_id: this.selectedChat?.broadcast_id,
            custom_chat: true,
          };
        }

        const newMessage = this.createMessageObject(
          messagePayload,
          'delivered',
          'Template'
        );

        this.updateToCurrentConversation(newMessage);
        this.cdr.detectChanges();
        this.scrollToBottom();
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to send message.',
        });
      }
    } catch (error) {
      console.error('Error sending template message:', error);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'An unexpected error occurred while sending the message.',
      });
    }
    finally {
      this.isLoading = false;
    }

    this.selectedTemplate = null;
    this.headerPlaceholders = [];
    this.buttonPlaceholders = [];
    this.bodyPlaceholders = [];
  }

  updateToCurrentConversation(newMessage: any) {
    if (this.selectedChatConversations?.chats?.length) {
      this.selectedChatConversations?.chats?.[
        this.selectedChatConversations.chats.length - 1
      ]?.messages?.push(newMessage);
    } else {
      this.selectedChatConversations?.chats.push({
        date: new Date().toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
        }),
        messages: [newMessage],
      });
    }

    this.chatList = this.chatList.map((chat: any) => {
      if (chat?.mobile_number === this.selectedChat?.mobile_number) {
        let lastMessage = newMessage?.header || newMessage?.body;

        if (!lastMessage) {
          if (this.fileTypes.includes(newMessage?.type)) {
            lastMessage = `<i class="pi ${this.getFileIconClass(
              newMessage.type
            )}"></i> ${this.toTitleCase(newMessage?.type)}`;
          } else {
            lastMessage = '(No Content)';
          }
        }

        chat.lastMessage =
          lastMessage.length > 45
            ? lastMessage.slice(0, 45) + '...'
            : lastMessage;
      }
      return chat;
    });
  }

  validateTemplatePlaceholders(): boolean {
    const requiredHeaderPlaceholders =
      this.selectedTemplate?.components
        .find((c: any) => c.type === 'HEADER')
        ?.text?.match(/{{\d+}}/g) || [];
    const requiredBodyPlaceholders =
      this.selectedTemplate?.components
        .find((c: any) => c.type === 'BODY')
        ?.text?.match(/{{\d+}}/g) || [];
    const requiredButtonPlaceholders =
      this.selectedTemplate?.components
        .find((c: any) => c.type === 'BUTTON')
        ?.text?.match(/{{\d+}}/g) || [];

    const isHeaderComplete =
      requiredHeaderPlaceholders.length ===
      this.headerPlaceholders.filter(Boolean).length;
    const isBodyComplete =
      requiredBodyPlaceholders.length ===
      this.bodyPlaceholders.filter(Boolean).length;
    const isButtonComplete =
      requiredButtonPlaceholders.length ===
      this.buttonPlaceholders.filter(Boolean).length;

    if (!isHeaderComplete || !isBodyComplete || !isButtonComplete) {
      console.warn('Incomplete placeholders detected');
      return false;
    }

    return true;
  }

  clearSelectedTemplate() {
    this.selectedTemplate = null;
    this.placeholders = [];
  }

  boldAndColorText(text: string): SafeHtml {
    const formattedText = text
      .replace(
        /\{\{(.*?)\}\}/g,
        '<span style="color: #007ad9;">{{ $1 }}</span>'
      )
      .replace(/\*(.*?)\*/g, '<strong>$1</strong>');
    return this.sanitizer.bypassSecurityTrustHtml(formattedText);
  }

  updateBreadCrumb() {
    const breadcrumbs = [{ label: Breadcrumb.CHAT, url: '' }];
    this.ngDynamicBreadcrumbService.updateBreadcrumb(breadcrumbs);
  }

  isButtonDisabled(): boolean {
    if (this.isBroadcastStep2) {
      return !this.broadcastName || !this.broadcastName.trim();
    }

    if (!this.isEmptyObject(this.selectedChat) && this.selectedChat.type === 'manual') {
      {
        return true;
      }
    }
    return this.selectedStudentCount === 0;
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  get shouldShowFloatingTemplateArea(): boolean {
    const hasSelectedChat = !this.isEmptyObject(this.selectedChat);
    const hasSelectedChatConversations = !this.isEmptyObject(
      this.selectedChatConversations
    );
    const isInactiveChat = this.selectedChat && !this.selectedChat.isActive;
    const isBroadcastChat = this.chatType === 'broadcast';

    return (
      hasSelectedChat &&
      ((isInactiveChat && hasSelectedChatConversations) || isBroadcastChat)
    );
  }

  routeToSettings() {
    this.router.navigate(['/settings']);
  }

  reloadUI() {
    window.location.reload();
  }

  private safeParseJSON(data: string): any {
    try {
      return data ? JSON.parse(data) : null;
    } catch (e) {
      console.warn("Failed to parse JSON data:", data);
      return null;
    }
  }

  ngOnDestroy() {
    if (this.fetchInterval) {
      clearInterval(this.fetchInterval);
    }
  }
}