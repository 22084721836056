<div
  class="sidebar"
  [ngStyle]="{ 'background-image': 'url(' + sideBarPattern + ')' }"
  [class.expanded]="isExpanded"
  (mouseleave)="shrinkSidebar()"
  (mouseenter)="expandSidebar()"
>
  <div class="img-cnt">
    <img
      class="sidebar-sourceImg"
      (error)="imageLoadError($event)"
      [src]="neoPatIcon"
      alt=""
    />
  </div>
  <div class="sidebar-icons">
    <ng-container *ngFor="let icon of iconsToShow">
      <button
        class="icon-fun {{ icon.isActive ? 'active' : '' }}"
        (click)="navClick(icon)"
        [ngStyle]="
          isExpanded
            ? { 'justify-content': 'flex-start', 'min-width': '60px' }
            : { 'justify-content': 'center' }
        "
      >
        <img [src]="icon.value" alt="" />
        <span *ngIf="isExpanded" class="sidebar-name">{{ icon.toolTip }}</span>
        <span *ngIf="icon.isBeta" class="beta-label">Beta</span>
      </button>
    </ng-container>

    <button class="icon-fun more-icon" *ngIf="!isExpanded">
      <i class="pi pi-ellipsis-h"></i>
    </button>
  </div>
</div>